//TODO: mixins structure

@mixin full-screen() {
  width: $one-hundred-vw;
  height: 100vh;
}

@mixin elipsize-text() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin get-clip-shape($shape) {
  clip-path: $shape;
  shape-outside: $shape;
}

@mixin center-horizontal($top: unset) {
  left: 50%;
  top: $top;
  transform: translateX(-50%);
}

@mixin center-vertical($left:unset) {
  top: 50%;
  left: $left;
  transform: translateY(-50%);
}

@mixin center() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-img() {
  display: block;
  margin: 0 auto;
  //transform: translateY(-50%);
  //TODO: fix vertical centering
}
