@import './forms/forms';


.footer-section {


  ul {
    padding-left: 0;
    list-style-type: none;
  }

  .bootstrap-grid-cell {
    overflow: hidden;
  }

  h1, .fb-submit-row {
    text-align: center;
  }

  svg.bi-envelope {
    margin-right: #{$xs-space}px;
  }

  &, & a {
    color: $main-color-theme-font;
  }

  background-color: $main-color-theme;
  padding: #{$sm-space}px;

  @media(min-width: $min-width-tablet) {
    padding: #{$xl-space}px;
  }

  hr {
    background-color: $main-color-theme-font;
    width: 70%;
  }

  .fb-footer-footerSection {
    margin: 48px 12px;
  }

  .fb-credits-footer-section {
    ul {
      margin-top: 48px;
    }
  }


  input, textarea {
    background-color: $secondary-color-theme;
  }

  input[type='text'], textarea {
    border-radius: 15px;
    padding: 8px 16px;
    color: $secondary-color-theme-emphasise-font;
    font-size: 16px;
    width: 100%;
    border: none;
  }

  input[type='checkbox'] {
    margin-right: 12px;
  }

  input:focus, textarea:focus {
    outline: none;
  }

  a {
    text-decoration: none;
  }

  .fb-label-input {
    margin: 12px 0;
  }

  .fb-subribe-to-newsletter {
    text-indent: 22px;
  }

  .fb-form-submit {
    width: 100%;
    background-color: $main-color-theme;
    border: 2px solid white;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    color: white;
  }


  svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  .fb-disclaimer {
    font-size: 0.8em;
    text-align: center;
  }
}
