.fb-blog-section {
  .article {
    h2 {
      margin: #{$lg-space}px 0;
    }

    blockquote {
      padding-left: 45px;
      position: relative;
      margin-top: 30px;
      color: $secondary-color-theme-emphasise-font;

      &::before {
        font-family: 'Knewave', cursive;
        font-size: #{$font-size-xl}px;
        content: '"';
        position: absolute;
        left: 25px;
        top: -34px;
        width: 45px;
        height: 45px;
      }
    }
  }
}
