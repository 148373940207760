
.fb-page {
  .fb-page-picture {
    margin: auto;
    width: 814px;
    height: 533px;
    object-fit: cover;
    object-position: center;

    @media(min-width: #{$min-width-lg-pc}px) {
      float: right;
      margin-top: 52px;
      margin-right: 52px;
      width: 500px;
      height: 500px;
      border-radius: 100%;
      padding: 20px;
      shape-outside: circle(282px at 45.4% 54.3%);
    }
  }
}