nav.navbar {
  background-color: $main-color-theme;

  .navbar-toggler {
    border: solid 2px $main-color-theme-font;
    padding-bottom: 8px;
    padding-top: 8px;
    outline: none;

    .navbar-toggler-icon {
      border-top: solid 2px $main-color-theme-font;
      border-bottom: solid 2px $main-color-theme-font;
      position: relative;
      width: 25px;
      height: 25px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 100%;
        height: 2px;
        left: 0;
        transform: translateY(-50%);
        background-color: $main-color-theme-font;
      }
    }
  }


  a {
    color: $main-color-theme-font;
  }

  .navbar-collapse {
    .nav-link {
      border-radius: 10px;
    }

    &:hover {
      .nav-link:hover {
        background-color: $main-color-dark-variant;
      }
    }

    &:not(:hover) {
      .nav-link.active {
        background-color: $main-color-dark-variant;
      }
    }
  }


  .navbar-collapse {
    .nav-item {
      cursor: pointer;
    }

    .nav-item.dropdown:hover {

      .dropdown-menu {
        border: unset;

        a:hover {
          background-color: $main-color-dark-variant;
          color: $main-color-theme-font;
        }

        margin: 0;
        background-color: $main-color-theme;
        display: block !important;
      }
    }
  }
}
