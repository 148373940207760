/******** MAIN COLOR ***********/
$main-color-theme: #cf5e32;
$main-color-dark-variant: darken($main-color-theme, 5%);
$main-color-light-variant: lighten($main-color-theme, 5%);
$main-color-theme-font: #f1f1ee;

/******** SECONDARY COLOR ***********/

$secondary-color-theme: #8ba997;
$secondary-color-theme-emphasise-font: #e0ddcd;
$secondary-color-theme-main-font: black;
$secondary-color-dark-variant: darken($secondary-color-theme, 5%);
$secondary-color-light-variant: lighten($secondary-color-theme, 5%);
