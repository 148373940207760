.picture {

	max-width: 100%;
	max-height: 100%;


	&.float {
		&.float-left {
			float: left;
		}

		&.float-right {
			float: right;
		}
	}


	&.cropped-picture {

		object-fit: cover;

		&.cropped-picture-center {
			object-position: calc(50% - 0px) calc(50% - 0px);
			width: 100%;
			height: 100%;
		}

		&.cropped-picture-bottom, &.cropped-picture-top, &.cropped-picture-center.cropped-picture-center-cut-horizontally {
			width: 100%;
			height: auto;

			//DOES NOT WORK ON IE
			&.cropped-picture-bottom {
				object-position: bottom;
			}

			//DOES NOT WORK ON IE
			&.cropped-picture-top {
				object-position: top;
			}
		}

		&.cropped-picture-left, &.cropped-picture-right, &.cropped-picture-center.cropped-picture-center-cut-vertically {
			height: 100%;
			width: auto;

			&.cropped-picture-left {
				object-position: left;
			}

			&.cropped-picture-right {
				object-position: right;
			}
		}
	}
}