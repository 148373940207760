.bootstrap-grid {
	.bootstrap-grid-cell {
		padding: 0;


		picture, img {
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
		}

	}
}