/**********Pages************************/
@import 'pages/MainGenericPage/genericPage';
@import 'pages/QuicklinkNavigation/quicklinksNavigation';
/**************Partials*********************/
@import 'partials/Navigation/navigation';
@import 'partials/Footer/footer';
@import 'partials/Blog/blog';
@import 'partials/Form/form';

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

html {
  font-size: 20px;

  body {
    background-color: $secondary-color-theme;
  }
}

//TODO: clean this file up!
button.disabled, input.disabled {
  filter: opacity(0.6);
}

.fb-website {

  #navbar-example3 {
    border-radius: 20px;
    left: 100px;
    top: 100px;
    width: 330px;
    position: fixed;
    z-index: 1;
  }

  .fb-flow-breaker {
    overflow: auto;
  }

  .fb-blog {

    hr {
      border: 0;
      height: 70px;
      background-image: url('https://www.filemonenbaukis.com/favicon.ico');
      background-repeat: no-repeat;
      background-position: center;
      position: relative;

      &::before, &::after {
        content: '';
        position: absolute;
        width: calc(50% - 150px);
        height: 1px;
        background-color: #CF5E32;
        top: 50%;
        transform: translateY(-50%);
        height: 5px;
      }

      &::before {
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;
        right: calc(50% + 50px);
      }

      &::after {
        border-top-right-radius: 100%;
        border-bottom-right-radius: 100%;

        left: calc(50% + 50px);
      }
    }

    .article#komende-activiteiten-individueel + hr {
      display: none;
    }

    a, i, strong, em {
      color: $secondary-color-theme-emphasise-font;
    }
  }

  /***********Flyer*******************/

  @media print {
    nav, footer {
      display: none;
    }
  }

  img {
    display: inline-block;
  }

  .fb-flyer {
    hr {
      margin: 80px 0;
    }

    .fb-page-picture {
      float: right;
      margin-top: 52px;
      margin-right: 52px;
      width: 500px;
      height: 500px;
      border-radius: 100%;
      padding: 20px;
      shape-outside: circle(282px at 45.4% 54.3%);
    }

    .fb-blog-text-area {
      .article {
        img {
          display: block;
          float: right;
          width: 250px;
          height: 250px;
        }
      }
    }
  }

  /**********SPACING******************/

  section.container, section.container-fluid {
    margin-top: #{$xl-space}px;
  }

  /**********Typography**************/
  font-family: $main-font;

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  /**************IMG's**********************/
  img {
    display: block;
  }
}
