.fb-form {

  .fb-label-input {
    position: relative;

    .fb-error-message {
      position: absolute;
      color: black;
      font-size: 0.8em;
    }

    input, textarea {
      margin-top: 26px;
    }
  }
}
