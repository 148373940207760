.fb-quicklinks-navigation {
	a {
		color: $main-color-theme-font;
	}

	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

	.fb-navigation-card-banner {
		height: 250px;
		margin: 20px 0;
		overflow: hidden;
		border-radius: 25px;
		border: solid 2px;
		border-color: $main-color-theme-font;

		@media (min-width: #{$min-width-lg-pc}px) {
			height: 400px; //TODO: this!
		}

		.picture {
			transition: 0.4s transform;
		}

		&:hover {
			.picture {
				transform: scale(1.1);
			}
		}
	}

	.fb-navigation-card-text-area {
		text-align: center;
		overflow: hidden;
	}

}