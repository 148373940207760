.covered {
	object-fit: cover;

	&.covered-width, &.covered-width-and-height {
		width: 100%;
	}

	&.covered-height, &.covered-width-and-height {
		height: 100%;
	}

	&.covered-full-screen {
		@include full-screen();
	}
}