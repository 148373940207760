.positioned {


	&.positioned-absolute {
		position: absolute;
	}

	&.positioned-relative {
		position: relative;
	}

	&.positioned-absolute {
		position: absolute;
	}

	&.positioned-horizontally-centered {
		@include center-horizontal();
	}

	&.positioned-vertically-centered {
		@include center-vertical();
	}

	&.positioned-centered {
		@include center();
	}

	&.positioned-relative {
		position: relative;
	}
}
